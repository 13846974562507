.max-width {
    max-width: 1400px !important;
    padding-inline: 20px !important;
    margin-inline: auto !important;

}

html {
    scroll-behavior: smooth;
}

.max-auto {
    width: auto !important;
}

.relative {
    z-index: 1;
}

.borde {
    border-radius: 39px;
    padding-block: 30px !important;
}

.gapeo {
    row-gap: 0.5rem !important;
}

.gapeo .font-montserratExtrabold {
    font-size: 30px !important;
}

/* .navbar .active {
    color: #35C5C0;
    text-decoration: underline;
} */

.mto {
    padding-block: 60px 70px;
}

.lg43r {
    max-width: 43rem;
    text-align: left;
}

.maiinisa {
    margin-right: 30px;
}

.gap-asas {
    gap: 20px;
}

.maiinisa h1 {
    font-size: 30px;
    font-weight: bold;
    font-family: 'montserrat-bold';
}

.footer-m {
    padding-block: 50px 35px;
}

.fsd {


    animation: movedown 0.3s forwards ease-in-out !important;

}

@keyframes movedown {
    from {
        top: -200px
    }

    to {
        top: 0
    }
}

@media (min-width:920px) {
    .card-content {
        min-height: 150px !important;
        position: relative;
        z-index: 1;
        border-radius: 10px;
        display: flex !important;
        /* align-items: center; */
        justify-content: center;
    }
}

@media (max-width:1400px) {
    .max-auto {
        width: 832px !important;
        height: auto !important;
        top: auto !important;
        bottom: 0 !important;

    }

    .ams {
        font-size: 4rem;
    }
}

@media (max-width:1024px) {
    .max-auto {
        width: 90% !important;
        margin: auto;
        height: auto !important;
        top: auto !important;
        bottom: 0 !important;

    }

    .alls {
        border-radius: 50px !important;
    }

    .mt30 {
        margin-top: 30px !important;
    }

    .shi8t {
        margin-bottom: 20px !important;

    }

    .anti {
        width: 98% !important;
    }

    .anti p.relative {
        display: inline;
    }
}

@media (max-width:768px) {
    .mar {
        border-radius: 50px !important;

    }

    .asasa {
        display: flex !important;
        justify-content: center;

    }

    .mas {
        max-width: none !important;
        width: 100% !important;
    }

    .sld {
        flex-direction: column;
    }

    .bans {
        border-radius: 88px !important;
    }

    .lg43r {
        padding-top: 50px !important;
    }
}

@media (max-width:500px) {

    .bans h3.text-4xl {
        font-size: 1.5rem !important;
    }
}